import * as Sentry from '@sentry/browser';
import { sentryErrorIntegration } from '@ifixit/sentry';

const ERROR_SAMPLE_RATE_PRODUCTION = 0.05;
const ERROR_SAMPLE_RATE_DEV = 0;

const TRACE_SAMPLE_RATE = App.isProduction ? 0.005 : 0;

const errorSampleRate = App.isProduction ? ERROR_SAMPLE_RATE_PRODUCTION : ERROR_SAMPLE_RATE_DEV;

let sentrySent = false;
const forceSentry = App.canIForceSentryReporting && /(forceSentry)/.test(window.location.search);
const performanceOptions = App.sentryPerformance
   ? {
        integrations: [Sentry.browserTracingIntegration(), sentryErrorIntegration()],
        tracesSampleRate: TRACE_SAMPLE_RATE,
     }
   : {
        integrations: [sentryErrorIntegration()],
     };

let sampleRate = forceSentry ? 1 : errorSampleRate;

Sentry.init({
   dsn: App.SENTRY_DSN_JS,
   environment: App.isProduction ? 'production' : 'dev',
   release: App.sentryRelease,
   ignoreErrors: ['TypeError: Network request failed'],
   ...performanceOptions,
   sampleRate: 1, // We do custom sampling in beforeSend()
   // Limit to one error per page load please cause we've seen instances of
   // thousands of errors being sent by one user.
   beforeSend: function (event) {
      // Skip if we've already sent an error
      if (sentrySent) {
         return null;
      }

      if (shouldIgnoreUserAgent()) {
         return null;
      }

      const shouldSendToSentry = Math.random() < sampleRate;
      if (!shouldSendToSentry) {
         return null;
      }

      sentrySent = true;
      return event;
   },
   normalizeDepth: 10,
});

Sentry.setTags(App.sentryTags);
Sentry.setUser({ ip_address: '{{auto}}' });

function shouldIgnoreUserAgent() {
   // Yeti is a web crawler / automation tool that seems to fake all api
   // responses and thus generates bunches of JS errors.
   return window.navigator.userAgent.match(/yeti/i);
}
